import { Box, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/system';

export const KbDrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
  position: 'sticky',
  top: '0',
  zIndex: 10,
  backgroundColor: '#fff',
  borderBottom: '1px solid #D1D6DD',
  fontWeight: 500,
  fontSize: '1.25rem',
}));

export const KbDrawerBody = styled(Box)(({ theme }) => ({
  padding: '1rem',
}));

export const KbValueAddTags = styled(Chip)(({ theme }) => ({
  marginRight: '.5rem',
  marginBottom: '.5rem',
  textTransform: 'capitalize',
}));

export const KbValueAddLists = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginInline: '-1rem',
  paddingInline: 0,
  marginBlock: 0,
}));

export const KbValueAddTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '1rem',
}));

export const KbValueAddListItem = styled('li')(({ theme }) => ({
  width: '50%',
  listStyle: 'none',
  paddingInline: '1rem',
  display: 'flex',
}));

export const KbValueAddListItemContent = styled(Box)(({ theme }) => ({
  paddingInline: '1rem',
  marginBottom: '1rem',
  boxShadow: '0 0 8px -1px rgba(0,0,0,.25)',
  borderRadius: 8,
  padding: '1rem',
  flex: 1,
  cursor: 'pointer',
  backgroundColor: '#fff',
}));

export const KbValueAddDetails = styled(Box)(({ theme }) => ({
  marginRight: '1rem',
  '& span': {
    fontWeight: 500,
  },
}));

export const KbValueAddAuthors = styled(Box)(({ theme }) => ({
  marginInline: '.5rem',
  fontWeight: 500,
  '&:after': {
    content: '","',
  },
  '&:last-child:after': {
    content: '""',
  },
}));
