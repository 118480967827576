import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Grid, Typography, Box, Card, Skeleton } from '@mui/material';
import { CategoryCard } from '../../atoms/Card/categoryCard';
import { styled } from '@mui/material/styles';
import { ITechCategory } from '../../../types/Categories';
import { axiosInstance } from '../../../Utils/AxiosInstance';
import { UserContext, UserContextType } from '../../../Context/UserContext';
import { IApiResponse, useFetchOrion } from '../../../Utils/hooks/useFetchOrion';
import { IOrionCategory } from '../../../types/types';

const KbCategories = styled(Box)(({}) => ({
  marginTop: '-9rem',
  paddingBottom: '4rem',
  '& .noDataText': {
    textAlign: 'center',
    width: '100vw',
    marginTop: '2rem',
  },
}));

const StyledCard = styled(Card)(({}) => ({
  display: 'inline-block',
  width: '100%',
  padding: '1rem',
  marginBottom: '1.5rem',
  border: 'none',
  boxShadow: '0 0 .625rem 0 rgba(0, 0, 0, 0.15)',
  minHeight: '213px',
}));

export const Categories = () => {
  const [categoriesData, setCategoriesData] = useState<ITechCategory[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const { homePageData, setHomePageData } = useContext<UserContextType>(UserContext);
  const { data: categoriesDataOrion }: IApiResponse<IOrionCategory[]> = useFetchOrion('tech/categories');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('tech-categories?populate=deep,3');
        if (response?.status === 200) {
          setHomePageData({ techCategories: response?.data?.data });
          setCategoriesData(response?.data?.data);
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    if (homePageData?.techCategories) {
      setCategoriesData(homePageData.techCategories);
      setLoading(false);
    } else {
      fetchData();
    }
  }, [homePageData, setHomePageData]);

  const handleRecentUpdates = useCallback(
    (categoryName: string) => {
      if (!categoriesData) return [];

      const category = categoriesData.find((d: any) => d?.attributes?.name === categoryName);
      const categoryRecentUpdates = category?.attributes?.recent_updates?.data || [];
      const techDigests = category?.attributes?.tech_digests?.data.filter(
        (td: any) => td?.attributes?.recent_updates?.data?.length > 0
      );

      const techDigestsRecentUpdates = techDigests?.reduce((acc: any, t: any) => {
        return acc.concat(t?.attributes?.recent_updates?.data || []);
      }, []);

      return [...categoryRecentUpdates, ...techDigestsRecentUpdates];
    },
    [categoriesData]
  );

  return (
    <KbCategories>
      <Grid container spacing={{ xs: 2, md: 6 }}>
        {loading ? (
          <Grid container spacing={5} sx={{ paddingLeft: '50px', marginTop: '2rem' }}>
            {Array.from({ length: 12 }).map((_, index) => (
              <Grid item xs={3} key={index}>
                <StyledCard>
                  {[...Array(3)].map((_, i) => (
                    <Skeleton key={i} animation='wave' height={60} />
                  ))}
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        ) : error ? (
          <Typography variant='h3' className='noDataText'>
            Something went wrong.
          </Typography>
        ) : categoriesData?.length ? (
          categoriesData
            .sort((a: ITechCategory, b: ITechCategory) => a.attributes.index - b.attributes.index)
            .map((item: ITechCategory, index: number) => {
              const {
                attributes: { name, slug, icon },
              } = item;
              const { url } = icon?.data?.attributes || {};
              return (
                <Grid item xs={6} md={3} key={name}>
                  <CategoryCard
                    item={item}
                    alt={name}
                    icon={url}
                    categoryName={name}
                    link={`/${slug}`}
                    blogAndValueAddLink={`/${name}`}
                    recentUpdates={handleRecentUpdates(name)}
                    stats={categoriesDataOrion?.find((i: IOrionCategory) => i.name === name)}
                  />
                </Grid>
              );
            })
        ) : (
          <Typography variant='h3' className='noDataText'>
            Sorry, we couldn't find any results
          </Typography>
        )}
      </Grid>
      {/* <KbDivider /> */}
    </KbCategories>
  );
};
