import {
  Box,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Drawer,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
} from '@mui/material';

import React, { useEffect, useRef, useState } from 'react';
import { KbDrawerHeader } from '../../Components/atoms/Card/navigationCard';

import { useParams, useLocation } from 'react-router-dom';

import { SortDropdown } from '../../Components/atoms/Dropdown';

import { FilterWrapper, KbLanguages, KbSelectLabel } from './style';
import Cross from '../../assets/images/cross.png';
import { axiosInstance } from '../../Utils/AxiosInstance';
import { useContext } from 'react';
import { UpdatesContext } from '../../Context/NewUpdatesContext';

import { RecentlyUpdatedCard } from '../../Containers/RecentlyUploaded';

import Icon from '../../Components/Icons/icons';
import { CircularLoader } from '../../Components/atoms/CircularLoader';

import html2canvas from 'html2canvas';
import { SubCategoryContent } from './subCategoryContent';
import { MaturityContent } from './maturityContent';
import { ExpertiseContent } from './expertiseContent';
import { UserContext, UserContextType } from '../../Context/UserContext';
import { ISubCategory } from '../../types/types';
import { ITechDigest } from '../../types/TechDigest';
import { titleToSlug } from '../../Utils/helper';
import { expertiseLevels } from '../../Constants/constants';

interface ITechDigestsPerExpertise {
  expertiseLevel: string;
  expertiseName: string;
  techDigests: ITechDigest[];
  count: number;
}

const Languages = () => {
  const { category } = useParams();
  const location = useLocation();

  const componentRef: any = useRef(null);
  const [download, setDownload] = useState(false);
  const [isChart, setIsChart] = useState(false);

  const { setCategory } = useContext<UserContextType>(UserContext);

  const [originalData, setOriginalData] = useState<any>([]);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [recentData, setRecentData] = useState<any>([]);
  const [expertiseLevel, setExpertiseLevel] = useState<any>([]);
  const [subCategories, setSubCategories] = useState<any>([]);
  const [popularSubCategory, setPopularSubCategory] = useState<any>([]);
  const [legendObj, setLegendObj] = useState<any>({});

  const [subsCategoriesFilterlist, setSubsCategoriesFilterlist] = useState<any>([]);
  const [popularity, setPopularity] = useState<any>(false);
  const { newUpdates, setNewUpdates, newUpdatesModal, setNewUpdatesModal } = useContext(UpdatesContext);
  const [viewAllUpdates, setViewAllUpdates] = useState<any>();
  const [groupBy, setGroupBy] = useState<string>('Maturity');
  const [techDigestsPerSubCategory, setTechDigestsPerSubCategory] = useState([]);

  const [techDigestsWithNoSubCategory, setTechDigestsWithNoSubCategory] = useState([]);
  const [hasUnMappedCategories, setHasUnMappedCategories] = useState(false);
  const [techDigestsPerExpertise, setTechDigestsPerExpertise] = useState<ITechDigestsPerExpertise[]>([]);

  const [chartLoaded, setChartLoaded] = useState(false);

  const maturityLevelsData: any = [
    { type: 'Innovators', data: [], label: 'Innovators' },
    { type: 'Early Adoptors', data: [], label: 'Early Adopters' },
    { type: 'Early Majority', data: [], label: 'Early Majority' },
    { type: 'Late Majority', data: [], label: 'Late Majority' },
  ];

  const formatMaturityLevelsData = (list: any) => {
    maturityLevelsData?.forEach((ml: any, i: number) => {
      const arr = list?.filter((item: any) => item?.attributes?.maturity_level?.data?.attributes?.name === ml.type);
      maturityLevelsData[i].data = [...arr];
    });
  };

  const fetchAllCategories = (data: any) => {
    const techWithOtherCategories = data[0].attributes.tech_digests.data?.filter(
      (tech: any) => tech.attributes.tech_sub_categories.data.length === 0
    );
    setTechDigestsWithNoSubCategory(techWithOtherCategories);
    if (techWithOtherCategories.length) {
      setHasUnMappedCategories(true);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        `tech-categories?filters[slug][$eqi]=${titleToSlug(category || '')}&&populate=deep,3`
      );
      if (response?.status === 200 && response?.data?.data) {
        setPageLoading(false);
        setError(false);
        setCategory({
          name: response?.data?.data[0].attributes.name,
          slug: response?.data?.data[0].attributes.slug,
        });
        let subcat = [...response?.data?.data]?.map((item: any) => {
          return item?.attributes?.tech_sub_categories;
        });

        setSubCategories(subcat?.flatMap((cate: any) => cate?.data));
        fetchAllCategories(response?.data?.data);
        setOriginalData(response?.data?.data);

        let subCategoriesSorted: any = {};
        let popularSubCategory: string[] = [];

        response?.data?.data.forEach((category: any) => {
          category?.attributes?.tech_digests?.data.forEach((techDigest: any) => {
            let subCategoryList = techDigest?.attributes?.tech_sub_categories.data.map(
              (data: any) => data.attributes.name
            );
            subCategoryList = subCategoryList && subCategoryList.length > 0 ? subCategoryList : ['Others'];

            subCategoryList.forEach((category: string) => {
              if (subCategoriesSorted[category]) {
                subCategoriesSorted[category] += 1;
              } else {
                subCategoriesSorted[category] = 1;
              }
            });
          });
        });

        if (subCategoriesSorted) {
          let sortedEntries = Object.entries(subCategoriesSorted).sort((a: any, b: any) => b[1] - a[1]);
          let sortedObj = Object.fromEntries(sortedEntries);
          popularSubCategory = Object.keys(sortedObj)
            .filter((key) => key !== 'Others')
            .slice(0, 4);
          let chartObj: any = {};
          sortedEntries.forEach((entry) => {
            if (popularSubCategory.includes(entry[0])) {
              if (chartObj[entry[0]]) {
                chartObj[entry[0]] = chartObj[entry[0]] + entry[1];
              } else {
                chartObj[entry[0]] = entry[1];
              }
            } else {
              if (chartObj['Others']) {
                chartObj['Others'] = chartObj['Others'] + entry[1];
              } else {
                chartObj['Others'] = entry[1];
              }
            }
          });

          let otherCount = chartObj['Others'];
          delete chartObj['Others'];
          let finalObj = chartObj;
          if (otherCount) {
            finalObj['Others'] = otherCount;
          }

          setLegendObj(finalObj);
          setPopularSubCategory(popularSubCategory);
        }

        response?.data?.data.forEach((category: any, i: any) => {
          maturityLevelsData?.forEach((maturityLevel: any, j: any) => {
            const dd = category?.attributes?.tech_digests?.data?.filter((td: any) => {
              return td?.attributes?.maturity_level?.data?.attributes?.name === maturityLevel?.type;
            });
            let groupedData = [...dd].reduce((acc: any, item: any) => {
              let subCategoryItems = item?.attributes?.tech_sub_categories.data.map(
                (data: any) => data.attributes.name
              );

              let subCategory = subCategoryItems && subCategoryItems.length > 0 ? subCategoryItems[0] : 'NA';

              if (acc[subCategory]) {
                acc[subCategory].push(item);
              } else {
                acc[subCategory] = [item];
              }

              return acc;
            }, {});

            let orderMap: any = {};
            popularSubCategory.forEach((item, index) => {
              orderMap[item] = index;
            });

            let foundKeys: string[] = [];
            let notFoundKeys: string[] = [];
            let groupedDataKeys: string[] = [];

            Object.keys(groupedData).forEach((key) => {
              if (popularSubCategory.includes(key)) {
                foundKeys.push(key);
              } else {
                notFoundKeys.push(key);
              }
            });

            foundKeys = foundKeys.sort((a, b) => orderMap[a] - orderMap[b]);

            groupedDataKeys = foundKeys;
            groupedDataKeys = popularSubCategory ? groupedDataKeys.concat(notFoundKeys) : Object.keys(groupedData);

            let sortedGroupData: any = {};
            groupedDataKeys.forEach((key) => {
              sortedGroupData[key] = groupedData[key];
            });

            maturityLevelsData[j].data = [...Object.values(sortedGroupData)].flat();
          });
        });

        setNewUpdates({
          count: response?.data?.data?.[0]?.attributes?.recent_updates?.data?.length,
          data: response?.data?.data?.[0]?.attributes?.recent_updates?.data,
        });

        const techCategoryRecentUpdates: any = response?.data?.data?.[0]?.attributes?.recent_updates?.data;
        const techDigestRecentUpdates: any = response?.data?.data?.[0]?.attributes?.tech_digests?.data?.filter(
          (t: any) => t?.attributes?.recent_updates?.data?.length
        );
        const tdRc: any = [];
        techDigestRecentUpdates?.forEach((rc: any) => {
          tdRc.push(...rc?.attributes?.recent_updates?.data);
        });

        setViewAllUpdates({
          count: [...techCategoryRecentUpdates, ...tdRc].length,
          data: [...techCategoryRecentUpdates, ...tdRc],
        });

        setRecentData(maturityLevelsData);
      }
    } catch (err) {
      setPageLoading(false);
      setError(true);
    }
    // const response = await axiosInstance.get(`tech-categories?filters[slug][$eqi]=${category}&&populate=deep,3`);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    const allData = [...originalData]?.flatMap((item: any) => {
      return item?.attributes?.tech_digests?.data;
    });

    let explvl: number = expertiseLevel?.length;
    let sublvl: number = subsCategoriesFilterlist?.length;
    let pop: boolean = popularity;

    const getExpertiseData = (list: any[]) => {
      return list.filter((item: any) => {
        return (
          expertiseLevel.includes(item?.attributes?.expertise_level) ||
          expertiseLevel.includes(String(item?.attributes?.learning))
        );
      });
    };

    const getSubCateData = (list: any[]) => {
      return list?.filter((item: any) =>
        item?.attributes?.tech_sub_categories?.data?.some((dt: any) =>
          subsCategoriesFilterlist.includes(dt?.attributes?.name)
        )
      );
    };

    const getPoplarityData = (list: any) => {
      return list?.filter((item: any) => item?.attributes?.popularity);
    };

    const explevel = explvl ? getExpertiseData(allData) : allData;
    const sublevel = sublvl ? getSubCateData(explevel) : explevel;
    const poplevel = pop ? getPoplarityData(sublevel) : sublevel;

    formatMaturityLevelsData(poplevel);

    setRecentData(maturityLevelsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expertiseLevel, subsCategoriesFilterlist, popularity]);

  const handleViewAllUpdates = () => {
    setNewUpdates({ count: viewAllUpdates?.count, data: [...viewAllUpdates?.data] });
    setNewUpdatesModal(true);
  };

  // update in the list (sorting data)
  const sorting = (type: boolean, key: string): void => {
    const list: any = [...recentData].map((data: any) => {
      const datalists = type
        ? data.data.sort((a: any, b: any) => a?.attributes?.[key]?.localeCompare(b?.attributes?.name))
        : data.data.sort((a: any, b: any) => b?.attributes?.[key]?.localeCompare(a?.attributes?.name));
      return { type: data.type, data: datalists, label: data.label };
    });
    setRecentData(list);
  };
  const selectExpertiseLevel = (exp: Array<{ title: string; value: string }>) => {
    if (exp?.length) {
      const arr: any = [];
      exp.forEach((e: any) => {
        if (e !== 'false' || e !== 'true') {
          const temp = e?.split(',');
          if (temp[0] !== undefined && temp[0] !== null) {
            arr.push(temp[0]);
          }
          if (temp[1] !== undefined && temp[1] !== null) {
            arr.push(temp[1]);
          }
        } else {
          arr.push(e === 'true' ? true : false);
        }
      });
      setExpertiseLevel(arr);
    } else {
      setExpertiseLevel(exp);
    }
  };

  const handleSubsCategories = (subs: any) => {
    if (subs?.length) {
      setSubsCategoriesFilterlist(subs);
    } else {
      setSubsCategoriesFilterlist(subs);
    }
  };
  const handlePopularity = (pop: boolean) => {
    pop ? setPopularity(pop) : setPopularity(pop);
  };
  const clearExpertiseLevel = () => {
    fetchData();
  };

  const handleClose = () => setNewUpdatesModal(false);

  useEffect(() => {
    return () => {
      setNewUpdates([]);
      setNewUpdatesModal(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (download && (chartLoaded || !isChart)) {
      if (componentRef.current === null) {
        return;
      }
      const scale = 5;
      html2canvas(componentRef.current, {
        useCORS: true,
        scrollX: 0,
        scrollY: 0,
        scale: scale,
        width: componentRef.current.width,
        height: componentRef.current.scrollHeight,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 0.95);
        const link = document.createElement('a');
        link.href = imgData;
        link.download = location.pathname ? location.pathname + '.jpeg' : 'Screenshot.jpeg';
        document.body.appendChild(link);

        link.click();
        setDownload(false);
        setChartLoaded(false);
        document.body.removeChild(link);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [download, chartLoaded]);

  const downloadFile = async () => {
    setDownload(true);
  };

  const handleGroupBy = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setGroupBy(value);

    if (value === 'Sub Category') {
      const techWithSubCategories = originalData[0].attributes.tech_digests.data?.filter(
        (tech: any) => tech.attributes.tech_sub_categories.data.length > 0
      );

      fetchAllCategories(originalData);

      const sortedSubCategories = subCategories?.sort((a: ISubCategory, b: ISubCategory) => {
        const orderA = a?.attributes?.order ?? null;
        const orderB = b?.attributes?.order ?? null;

        if (orderA !== null && orderB !== null) {
          return orderA - orderB;
        } else if (orderA !== null) {
          return -1;
        } else if (orderB !== null) {
          return 1;
        } else {
          return 0;
        }
      });

      const techDigestWithSubCategory = sortedSubCategories?.map((subCategory: ISubCategory) => ({
        subCategory: subCategory.attributes.name,
        techDigests: techWithSubCategories.filter(
          (tech: any) => tech.attributes.tech_sub_categories.data[0].attributes.name === subCategory.attributes.name
        ),
      }));

      if (techDigestsWithNoSubCategory.length > 0) {
        techDigestWithSubCategory.push({
          subCategory: 'Others',
          techDigests: [...techDigestsWithNoSubCategory],
        });
        setHasUnMappedCategories(true);
      }

      setTechDigestsPerSubCategory(techDigestWithSubCategory);
    }

    if (value === 'Expertise') {
      const groupedByExpertiseArr = expertiseLevels.map((level) => {
        const techDigestData = originalData[0].attributes.tech_digests.data?.filter((tech: any) => {
          return level.value === 'zero'
            ? tech.attributes.expertise_level === null || tech.attributes.expertise_level === undefined
            : tech.attributes.expertise_level === level.value;
        });
        return {
          expertiseLevel: level.value,
          expertiseName: level.level,
          techDigests: techDigestData,
          count: techDigestData?.length,
        };
      });

      setTechDigestsPerExpertise(groupedByExpertiseArr);
    }
  };

  return (
    <KbLanguages>
      {pageLoading ? (
        <CircularLoader />
      ) : error ? (
        <Typography variant='h3' className='noDataText'>
          Something went wrong.
        </Typography>
      ) : (
        <>
          <FilterWrapper>
            {viewAllUpdates?.count ? (
              <Typography onClick={handleViewAllUpdates} sx={{ mr: 0.5, cursor: 'pointer' }}>
                View All Updates
              </Typography>
            ) : null}

            <FormControl sx={{ minWidth: 150, ml: '1rem' }}>
              <KbSelectLabel id='controlled-open-select-label'>Group By</KbSelectLabel>
              <Select
                labelId='controlled-open-select-label'
                // id='controlled-open-select'
                name='controlled-open-select'
                value={groupBy}
                sx={{ backgroundColor: '#fff' }}
                label='Sub Category'
                onChange={handleGroupBy}
              >
                <MenuItem value={'Sub Category'} disabled={subCategories?.length === 0}>
                  Sub Category({subCategories.length === 0 ? 0 : subCategories.length + (hasUnMappedCategories ? 1 : 0)}
                  )
                </MenuItem>
                <MenuItem value={'Maturity'}>Maturity</MenuItem>
                <MenuItem value={'Expertise'}>Expertise</MenuItem>
              </Select>
            </FormControl>
            <SortDropdown
              label='Filter by'
              icon='filter'
              type='filter'
              expertiseLevel={expertiseLevel}
              selectExpertiseLevel={selectExpertiseLevel}
              clearExpertiseLevel={clearExpertiseLevel}
              subCategories={subCategories}
              handleSubsCategories={handleSubsCategories}
              handlePopularity={handlePopularity}
            />
            <SortDropdown label='Sort By' type='sort' sorting={sorting} icon='sort' />
            <Box style={{ marginLeft: '1rem' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChart}
                    onChange={(e: any) => {
                      setIsChart(e.target.checked);
                    }}
                    name={'isChart'}
                    color='primary'
                    disabled={popularSubCategory?.length === 0}
                  />
                }
                label={'Chart'}
              />
            </Box>
            <Box style={{ cursor: 'pointer' }}>
              <Icon kind={'download'} onClick={downloadFile} />
            </Box>
          </FilterWrapper>

          {groupBy === 'Maturity' && (
            <MaturityContent
              recentData={recentData}
              componentRef={componentRef}
              category={category}
              download={download}
              isChart={isChart}
              popularSubCategory={popularSubCategory}
              legendObj={legendObj}
              setChartLoaded={setChartLoaded}
            />
          )}

          {groupBy === 'Sub Category' && <SubCategoryContent data={techDigestsPerSubCategory} />}

          {groupBy === 'Expertise' && <ExpertiseContent data={techDigestsPerExpertise} />}

          <Drawer open={newUpdatesModal} onClose={handleClose} anchor={'right'}>
            <Box role='presentation' sx={{ paddingBottom: '6.5rem' }}>
              <KbDrawerHeader>
                {/* <NavigationImg src={process.env.REACT_APP_KBTECH_STRAPI_BASE_URL + src} alt={alt} /> */}

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography component={'p'} textTransform={'capitalize'}>
                    {category}
                  </Typography>
                </div>
                <Button onClick={handleClose}>
                  <img src={Cross} alt='Cross Icon' />
                </Button>
              </KbDrawerHeader>
              <RecentlyUpdatedCard recentUpdateData={newUpdates?.data} />
            </Box>
          </Drawer>
        </>
      )}
    </KbLanguages>
  );
};

export default Languages;
