import { Breadcrumbs, Box, styled } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';
import { useContext } from 'react';
import { UpdatesContext } from '../../../Context/NewUpdatesContext';

const Badge = styled(Box)(({ theme }) => ({
  padding: '0.2rem 0.40rem',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: '1rem',
  fontSize: '0.85rem',
  marginLeft: '0.5rem',
  cursor: 'pointer',
}));

const formatBreadcrumbItem = (item: string, isLastElement: boolean) =>
  decodeURIComponent(item)
    .split(isLastElement ? '-' : /(?=[A-Z][a-z])|-/)
    .join(' ');

const Breadcrumb = () => {
  const location = useLocation();
  const { newUpdates, setNewUpdatesModal } = useContext(UpdatesContext);
  const handleOpen = () => setNewUpdatesModal(true);

  const pathnames = location.pathname.split('/').filter((item) => item);

  return (
    <Breadcrumbs
      separator={<>&nbsp;{'>'}&nbsp;</>}
      aria-label='breadcrumb'
      sx={{
        '& li': {
          display: 'inline-flex',
        },
      }}
    >
      <Box display={'flex'}>
        <Link to='/' className={pathnames.length === 0 ? 'inactive' : 'active'}>
          Home
        </Link>
        {newUpdates?.count > 0 && pathnames.length === 0 && <Badge onClick={handleOpen}>{newUpdates.count}</Badge>}
      </Box>
      {pathnames.map((item, i) => {
        const to = `/${pathnames.slice(0, i + 1).join('/')}`;
        const lastIndex = pathnames.length - 1;
        return (
          <Box display={'flex'} key={i}>
            <Link to={to} className={i === lastIndex ? 'inactive' : 'active'}>
              {formatBreadcrumbItem(item, i === lastIndex)}
            </Link>
            {newUpdates?.count > 0 && i === lastIndex && <Badge onClick={handleOpen}>{newUpdates.count}</Badge>}
          </Box>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
