import { styled } from '@mui/material/styles';

export const KbOgCardUl = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  paddingLeft: 0,
}));
export const KbOgCardLi = styled('li')(({ theme }) => ({
  listStyle: 'none',
  padding: '1rem',
  cursor: 'pointer',
}));
export const KbOgCardLiContent = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  width: 330,
  borderRadius: 10,
  boxShadow: '0 0 8px -1px rgba(0,0,0,.25)',
  height: '100%',
}));

export const KbOgDetailsCard = styled('div')(({ theme }) => ({
  padding: '1rem',
}));

export const KbOgDetailsCardTitle = styled('h3')(({ theme }) => ({
  margin: 0,
  marginBottom: '.5rem',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '2',
  overflow: 'hidden',
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: 1.5,
}));

export const KbOgDetailsCardAuthorInfo = styled('div')(({ theme }) => ({
  '& label, & span': {
    fontSize: '.75rem',
  },
  '& span': {
    fontWeight: 500,
  },
}));
