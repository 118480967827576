import React, { useState } from 'react';
import { Box, Drawer, Button } from '@mui/material';
import Cross from '../../assets/images/cross.png';
import { MarkdownComponent } from '../../Components/atoms/Markdown';
import {
  KbValueAddListItem,
  KbValueAddListItemContent,
  KbValueAddTitle,
  KbValueAddDetails,
  KbValueAddAuthors,
  KbValueAddTags,
  KbDrawerHeader,
  KbDrawerBody,
} from './style';

export enum ValueAddType {
  HARD_PROBLEMS = 'Hard Problem',
}

const getValueAddType = (type: string) => {
  switch (type) {
    case 'HARD_PROBLEMS':
      return ValueAddType.HARD_PROBLEMS;
    default:
      return ValueAddType.HARD_PROBLEMS;
  }
};

const ValueAddItem = ({ itemDetails }: any) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowModal(false);
  };

  const openDrawer = () => {
    setShowModal(true);
  };

  return (
    <KbValueAddListItem onClick={openDrawer}>
      <KbValueAddListItemContent>
        <KbValueAddTitle variant='h4'>{itemDetails.customersValueAdd.title}</KbValueAddTitle>
        <Box display='flex' marginBottom='.75rem'>
          {(itemDetails?.customersValueAdd?.teamId?.teamName || itemDetails?.customersValueAdd?.type) && (
            <>
              {itemDetails?.customersValueAdd?.teamId?.teamName && (
                <KbValueAddDetails>
                  <label>Team: </label>
                  <span>{itemDetails?.customersValueAdd?.teamId?.teamName}</span>
                </KbValueAddDetails>
              )}
              {itemDetails?.customersValueAdd?.type && (
                <KbValueAddDetails>
                  <label>Type: </label>
                  <span>{getValueAddType(itemDetails?.customersValueAdd?.type)}</span>
                </KbValueAddDetails>
              )}
            </>
          )}
        </Box>
        {itemDetails?.customersValueAdd?.ownerId && (
          <Box display='flex' flexWrap='wrap' marginBottom='.75rem'>
            <label>Authors: </label>
            {itemDetails?.customersValueAdd?.ownerId.map((owner: any, index: any) => (
              <KbValueAddAuthors key={index}>{owner?.name}</KbValueAddAuthors>
            ))}
          </Box>
        )}
        {itemDetails?.fileDetails?.tags && (
          <Box display='flex'>
            <Box component='label' marginRight='.5rem'>
              Domain:{' '}
            </Box>
            <Box display='flex' flexWrap='wrap' marginBottom='-.5rem'>
              {itemDetails?.fileDetails?.tags?.split(',').map((tag: string, index: string) => (
                <KbValueAddTags key={index} label={tag} size='small' />
              ))}
            </Box>
          </Box>
        )}
      </KbValueAddListItemContent>
      <Drawer open={showModal} onClose={handleClose} anchor='right'>
        <Box role='presentation' sx={{ paddingBottom: '6.5rem' }}>
          <KbDrawerHeader>
            {itemDetails?.customersValueAdd?.title}
            <Button onClick={handleClose}>
              <img src={Cross} alt='Cross Icon' />
            </Button>
          </KbDrawerHeader>
          <KbDrawerBody>
            <MarkdownComponent>{itemDetails?.fileDetails?.summary}</MarkdownComponent>
          </KbDrawerBody>
        </Box>
      </Drawer>
    </KbValueAddListItem>
  );
};

export default ValueAddItem;
