import { Typography, Container } from '@mui/material';
import { KbValueAddLists } from './style';
import ValueAddItem from './ValueAddItem';
import { IValueAdd } from '../../types/ValueAdd';

export const CategoryValueAdd = ({ valueAdds }: any) => {
  return (
    <Container sx={{ paddingTop: '1rem' }}>
      {valueAdds?.length ? (
        <KbValueAddLists>
          {valueAdds.map((d: IValueAdd, index: number) => (
            <ValueAddItem key={index} itemDetails={d} />
          ))}
        </KbValueAddLists>
      ) : (
        <Typography variant='h3' className='noDataText'>
          Currently, no value add are associated with this technology
        </Typography>
      )}
    </Container>
  );
};
