import { Box, Button, styled } from '@mui/material';
import { Heading } from '../../Components/Heading';

export const KBSButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  fontSize: '1rem',
  margin: 'auto',
  borderRadius: '.375rem',
  padding: '0.90625rem 1rem',
  cursor: 'pointer',
  color: '#555',
  textTransform: 'capitalize',
  border: '1px solid #DEDEED',
  transition: 'all 0.3s ease 0s',
  lineHeight: 1.14,
  textAlign: 'center',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  '& img': {
    width: '1rem',
    height: '1rem',
    marginRight: '.625rem',
  },
}));

export const Form = styled(Box)(() => ({
  background: 'linear-gradient(90deg, rgba(21,183,190,1) 0%, rgba(23,183,217,1) 100%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Card = styled(Box)(() => ({
  width: '23.875rem',
  height: '20.9375rem',
  backgroundColor: '#fff',
  borderRadius: '.75rem',
  boxShadow: '0 0 .75rem 0 #00000029',
  padding: '4.625rem 3.75rem',
  textAlign: 'center',
}));

export const Illustration = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export const AccessDeniedCard = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  boxShadow: '0 0 .75rem 0 #00000029',
  padding: '1.75rem',
  borderRadius: '.75rem',
  minWidth: '42.625rem',
}));

export const AccessDeniedTitle = styled(Heading)(() => ({
  fontSize: '2rem',
  lineHeight: 1.5,
  fontWeight: 500,
  color: '#0B93DF',
  marginBlock: '1.25rem',
}));

export const AccessDeniedText = styled(Heading)(() => ({
  fontSize: '1rem',
  lineHeight: 1.75,
  fontWeight: 500,
  color: '#555',
  maxWidth: '23.875rem',
  textAlign: 'center',
}));

export const FormHeading = styled(Heading)(() => ({
  fontSize: '1.25rem',
  color: '#555',
  lineHeight: 1.15,
  fontWeight: 400,
  marginTop: '1rem',
  marginBottom: '2.1875rem',
}));

export const PageHeading = styled(Heading)(() => ({
  fontSize: '2rem',
  color: '#343434',
  lineHeight: 1.25,
  fontWeight: 500,
  marginBottom: '5.4875rem',
  maxWidth: '23.5625rem',
  textAlign: 'center',
}));

export const AccessDeniedContainer = styled(Box)(() => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

export const LoginFormContainer = styled(Box)(() => ({
  flexGrow: 1,
  display: 'flex',
  height: '100%',
}));
