export const articleTypeLabel = {
  blogTlArticle: 'BLOGS & TL ARTICLES',
  hardProblem: 'Hard Problem',
};

export const authorLabel = {
  talentica: 'Talentica',
};

export const statisticsLabel = {
  valueAdds: 'Value Adds',
  teams: 'Teams',
  publications: 'Publications',
};

export const expertiseMapping: { [key: string]: number } = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
};

export const expertiseLevels = [
  { level: 'Novice', value: 'one' },
  { level: 'Intermediate', value: 'two' },
  { level: 'Proficient', value: 'three' },
  { level: 'Expert', value: 'four' },
  { level: 'Master', value: 'five' },
];
