import { margin, styled } from '@mui/system';
import { Box, Popover } from '@mui/material';
export const KbNavigationCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  borderBottom: '0.75px solid #BEBEBE',
  textDecoration: 'none',
  color: theme.palette.primary.dark,
  position: 'relative',
  zIndex: 50,
  paddingInline: '1rem',
  '&:last-child': {
    borderBottom: 0,
  },
  '& .navigationleftSide': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .navigationRightSide': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '.content': {
      paddingLeft: '0.5rem',
      p: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    '.icon-section': {
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
  '& .new-entry-badge': {
    top: '-0.8125rem',
    left: 0,
    margin: 0,
    '> span': {
      height: '0.875rem',
      padding: '0 5px',
      fontSize: '0.5rem',
    },
  },
}));

export const Badge = styled(Box)(({ theme }) => ({
  content: `""`,
  position: 'absolute',
  padding: '0.288rem',
  backgroundColor: theme.palette.primary.main,
  right: '-0.313rem',
  top: '0.188rem',
  borderRadius: '0.75rem',
  transform: 'translate(-50%,-50%)!important',
}));

export const styleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '0.8rem 1.2rem',
  borderRadius: '0.625rem',
  overflow: 'auto',
  outline: '0',
};

export const KbExpertise = styled('div')(({ theme }) => ({
  display: 'flex',
  '& .dot': {
    width: '0.813rem',
    height: '0.313rem',
    borderRadius: '0.5rem',
    marginRight: '0.25rem',
    backgroundColor: '#D1D6DD ',
    '&.active': {
      backgroundColor: '#0B93DF',
    },
    '&.low': {
      backgroundColor: '#FF7A00',
    },
    '&.avg': {
      backgroundColor: '#0B93DF',
    },
    '&.high': {
      backgroundColor: '#10B857',
    },
  },
}));

export const PopoverContent = styled(Box)(({ theme }) => ({
  padding: '1rem',
  fontSize: 12,
  color: theme.palette.text.secondary,
  width: '20rem',
}));

export const ModalHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '1rem',
  paddingBottom: '1rem',
  borderBottom: '1px solid #DDD',
}));

export const ModalBody = styled(Box)(({ theme }) => ({
  minHeight: '25rem',
  overflow: 'auto',
  marginBottom: '1rem',
}));

export const CustomBadge = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: '1rem',
  padding: '2px 2px',
  textAlign: 'center',
  height: '0.65rem',
  width: '0.65rem',
  marginRight: '0.5rem',
}));

export const infoIcon = () => ({ marginLeft: '0.5rem', cursor: 'pointer' });

export const PopoverStyle = styled(Popover)(({ theme }) => ({
  borderRadius: '0px',
  textAlign: 'justify',
}));

export const RecentUpdateBadge = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: '1rem',
  padding: '2px 2px',
  textAlign: 'center',
  height: '1.125rem',
  width: '1.125rem',
  marginRight: '0.2rem',
  color: '#fff',
  fontSize: '.75rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const IconSection = styled(Box)<{ marginLeft?: string | number }>(({ marginLeft }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  marginLeft: marginLeft || '0',
}));
