import { useParams } from 'react-router-dom';
import { IApiResponse, useFetchOrion } from '../../Utils/hooks/useFetchOrion';
import { useMemo } from 'react';
import { CategoryBlog } from './CategoryBlog';
import { Container } from '@mui/material';
import { IOrionCategory, IOrionTechnology } from '../../types/types';
import { CircularLoader } from '../../Components/atoms/CircularLoader';

export const CategoryBlogs = () => {
  const { category } = useParams();
  const { data, loading }: IApiResponse<IOrionCategory> = useFetchOrion(`tech/category/${category}/blogs`);

  const blogs = useMemo(() => {
    return data?.technologies?.flatMap((tech: IOrionTechnology) => tech.blog) || [];
  }, [data]);

  if (loading) return <CircularLoader marginTop='10vh' />;

  return (
    <Container>
      <CategoryBlog data={blogs} />
    </Container>
  );
};
