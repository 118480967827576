import { useState, useEffect, useCallback } from 'react';
import { axiosInstance } from '../OrionAxiosInstance';
import { AxiosError } from 'axios';

export type IApiResponse<T> = {
  data: T | null;
  error: AxiosError | null;
  loading: boolean;
};

export const useFetchOrion = <T>(url: string): IApiResponse<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const getAPIData = useCallback(async () => {
    try {
      const res = await axiosInstance.get(url);
      setData(res.data);
    } catch (error: AxiosError | any) {
      setError(error.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  }, [url]);

  useEffect(() => {
    getAPIData();
  }, [getAPIData]);

  return { data, error, loading };
};
