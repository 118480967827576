import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Skeleton, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFetch, IApiResponse } from '../../../Utils/hooks/useFetch';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation } from 'swiper';
import { Heading } from '../../Heading';
import { IRecentUpdate } from '../../../types/TechDigest';
import dateFormat from 'dateformat';
import RecentUpdateDrawer from './RecentUpdateDrawer';
import { CustomCardContent, RecentUpdatesContainer, Title, UpdateCard } from './styles';

// Main Component
export const RecentUpdates: React.FC = () => {
  const [recentUpdates, setRecentUpdates] = useState<IRecentUpdate[]>([]);
  const [selectedRecentUpdate, setSelectedRecentUpdate] = useState<IRecentUpdate | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { data: dataSet, loading }: IApiResponse<any> = useFetch('/recent-updates');

  const handleToggleDrawer = (e: React.MouseEvent<HTMLElement>, data?: IRecentUpdate) => {
    e.stopPropagation();
    setIsDrawerOpen((prev) => !prev);
    setSelectedRecentUpdate(data || null);
  };

  useEffect(() => {
    if (dataSet) {
      const today = new Date();
      const sortedUpdates = [...dataSet]
        .filter((item) => {
          const publishedDate = new Date(item.attributes.publishedAt);
          return publishedDate <= today;
        })
        .sort((a, b) => new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime());

      setRecentUpdates(sortedUpdates.slice(0, 10));
    }
  }, [dataSet]);

  if (loading) {
    return (
      <>
        <Skeleton animation='wave' variant='rectangular' width={'100%'} height={27} sx={{ mb: '1.5rem' }} />
        <Grid container spacing={3}>
          <Grid container spacing={3}>
            {Array.from({ length: 3 }).map((_, index) => (
              <Grid item xs={4} key={index}>
                <Card sx={{ display: 'inline-block', width: '100%', padding: '1rem', marginBottom: '3rem' }}>
                  <Skeleton animation='wave' variant='rectangular' width={'100%'} height={20} />
                  <Box sx={{ mt: 1.5 }}>
                    <Skeleton animation='wave' height={17} />
                    <Skeleton animation='wave' height={17} />
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <RecentUpdatesContainer>
      <Heading title='Recent Updates' variant='h3' sx={{ mb: '1.5rem' }} />
      <Swiper modules={[Navigation]} spaceBetween={50} slidesPerView={3} navigation>
        {recentUpdates?.map((update, index) => (
          <SwiperSlide key={index} className='p-0'>
            <UpdateCard>
              <CustomCardContent onClick={(e) => handleToggleDrawer(e, update)}>
                <Box sx={{ flex: 1 }}>
                  <Title variant='h6' gutterBottom>
                    {update?.attributes?.title}
                  </Title>
                </Box>
                <Box>
                  <Typography variant='body2' color='textSecondary'>
                    By {update?.attributes?.author}
                  </Typography>
                  <Typography variant='body2' color='textSecondary'>
                    Published on {dateFormat(update?.attributes?.publishedAt, 'fullDate')}
                  </Typography>
                </Box>
              </CustomCardContent>
            </UpdateCard>
          </SwiperSlide>
        ))}
      </Swiper>
      <RecentUpdateDrawer
        handleCloseDrawer={handleToggleDrawer}
        openDrawer={isDrawerOpen}
        data={selectedRecentUpdate || ({} as IRecentUpdate)}
      />
    </RecentUpdatesContainer>
  );
};
