import { List, Tooltip, Typography } from '@mui/material';
import { RightSideContent } from '../../Components/molecules/RightSideBar';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { axiosInstance } from '../../Utils/OrionAxiosInstance';

const KbExperts = styled(List)(({ theme }) => ({
  paddingTop: 0,
  '& .MuiTypography-body1': {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: 1.14,
    textTransform: 'capitalize',
  },
  '& .MuiTypography-body2': {
    fontSize: '1rem',
    lineHeight: 1.12,
    fontWeight: 'normal',
  },
  '& .MuiDivider-root': {
    borderColor: '##BEBEBE',
  },
}));

const KbTeamsCardWrapper = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginInline: '-1rem',
  paddingInline: 0,
  marginBlock: 0,
}));

const KbTeamsCard = styled('li')(({ theme }) => ({
  width: '25%',
  listStyle: 'none',
  paddingInline: '1rem',
  height: '100%',
}));

const KbTeamsCardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1rem',
  boxShadow: '0 0 8px -1px rgba(0,0,0,.25)',
  borderRadius: 8,
  padding: '1rem',

  '& img, & svg': {
    marginRight: 10,
    flexShrink: 0,
  },
}));

const KbTeamsLabel = styled('span')(({ theme }) => ({
  fontSize: '.875rem',
}));

const KbTeamsInfo = styled('span')(({ theme }) => ({
  fontSize: '.875rem',
}));

const KbTeamsCustomerName = styled('span')(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 500,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'block',
  overflow: 'hidden',
  width: 'fit-content',
}));

interface ExpertsInterface {
  data?: any;
  tabName?: any;
  valueAddsData: any;
  blogsData: any;
  teamsData: any;
}

export const Experts = ({ data, valueAddsData, blogsData, teamsData }: ExpertsInterface) => {
  const [expertList, setExpertList] = useState<any[]>([]);
  const [expertData, setExpertData] = useState<any[]>([]);
  const [teamsForTechnology, setTeamsForTechnology] = useState<any[]>([]);
  const [teamsForExpert, setTeamsForExpert] = useState<any[]>([]);

  const getValueAddsExpertList = (valueAddsData: any, blogsData: any) => {
    const expertList: any[] = [];

    if (valueAddsData) {
      valueAddsData.forEach((valueAdd: any) => {
        const owners = valueAdd?.customersValueAdd?.ownerId;
        if (Array.isArray(owners)) {
          owners.forEach((expert: any) => {
            if (expert && !expertList.some((c: { name: string }) => c.name === expert.name)) {
              expertList.push(expert);
            }
          });
        }
      });
    }

    if (blogsData) {
      blogsData.forEach((blog: any) => {
        const author = blog?.attributes?.author;
        const email = blog?.attributes?.email;
        if (author && !expertList.some((c: { name: string }) => c.name === author)) {
          expertList.push({ name: author, email: email ? email : '-' });
        }
      });
    }

    if (teamsData) {
      teamsData.forEach((team: any) => {
        teamsForTechnology.push(team?.customerName);
      });
    }
    return expertList;
  };

  useEffect(() => {
    const experts = getValueAddsExpertList(valueAddsData, blogsData);
    setExpertList(experts);
  }, [valueAddsData, blogsData]);

  useEffect(() => {
    if (expertList.length > 0) {
      const promises = expertList.map((expert) => {
        return axiosInstance.get(`/user?email=${expert.email}`);
      });

      Promise.all(promises)
        .then((results) => {
          setExpertData(results);
        })
        .catch((error) => {
          console.error('Error fetching expert data:', error);
        });
    }
  }, [expertList]);

  useEffect(() => {
    const allMatchingTeams = expertData?.reduce((acc: any, expert: any) => {
      const matchingTeams = expert?.data?.customers?.map((customer: any) => {
        const matchingTeamCustomer = teamsForTechnology?.find((teamCustomer) => teamCustomer === customer.customerName);
        return matchingTeamCustomer;
      });

      if (expert?.data?.name) {
        acc[expert.data.name] = matchingTeams;
      }

      return acc;
    }, {});

    setTeamsForExpert(allMatchingTeams);
  }, [expertData, teamsForTechnology]);

  return (
    <>
      <RightSideContent>
        <KbTeamsCardWrapper>
          {expertData?.length ? (
            expertData.map((expert, index) =>
              expert.data.name ? (
                <KbTeamsCard key={expert.data.name + index}>
                  <KbTeamsCardContent>
                    <Tooltip title={expert.data.name} arrow placement='top'>
                      <KbTeamsCustomerName>{expert.data.name}</KbTeamsCustomerName>
                    </Tooltip>
                    <div>
                      {/* <div>
                        <KbTeamsLabel>Teams: </KbTeamsLabel>
                        <KbTeamsInfo>
                          {(teamsForExpert[expert.data.name] || [])
                            .filter((item: any) => item !== undefined)
                            .join(', ') || 'No customers found'}
                          {/* {expert.data.teams.map((team: any) => team.teamName).join(', ') || 'No customers found'}
                        </KbTeamsInfo>
                      </div> */}
                      <div>
                        <KbTeamsLabel>Email : </KbTeamsLabel>
                        <KbTeamsInfo>{expert.data.email ? expert.data.email : '-'}</KbTeamsInfo>
                      </div>
                    </div>
                  </KbTeamsCardContent>
                </KbTeamsCard>
              ) : (
                <div key={index}> </div>
              )
            )
          ) : (
            <Typography variant='h3' className='noDataText'>
              Sorry, we couldn't find any results
            </Typography>
          )}
        </KbTeamsCardWrapper>
      </RightSideContent>
    </>
  );
};
