import { useState, useEffect } from 'react';
import { axiosInstance } from '../AxiosInstance';

export type IApiResponse<T> = {
  data: T | null;
  error: string | null;
  loading: boolean;
};

export const useFetch = <T>(url: string): IApiResponse<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getAPIData = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(url);
      setData(res?.data?.data);
    } catch (error: any) {
      setError(error.response?.data?.message || 'An error occurred');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (url) {
      getAPIData();
    }
  }, [url]);

  return { data, error, loading };
};
