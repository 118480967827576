import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { LeftSideBarHeader } from '../../Components/molecules/LeftSideBar';
import { useParams } from 'react-router-dom';
import { IApiResponse, useFetch } from '../../Utils/hooks/useFetch';
import { ITechDigest, TechDigestAttributes } from '../../types/TechDigest';
import { ArticleDetailedContent } from './articalDetailedContent';
import { BriefDetails } from './briefDetails';
import { ArticleListingContent } from './articalListingContent';
import { TechTalks } from './techTalks';
import { Experts } from './experts';
import { Team } from './team';
import { CircularLoader } from '../../Components/atoms/CircularLoader';
import { RecentlyUpdatedCard } from '../../Containers/RecentlyUploaded';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { theme } from '../../theme';
import { OurExpertise } from './ourExpertise';
import TechSummary from '../../assets/images/tech-summary.svg';
import TechSummaryRed from '../../assets/images/tech-summary-red.svg';
import { BlogsTlArticles } from './blogsAndTlArticles';
import Blogs from '../../assets/images/blogs.svg';
import blogsRed from '../../assets/images/blogsred.svg';
import recentUpdates from '../../assets/images/recentupdates.svg';
import recentUpdatesRed from '../../assets/images/recentupdatesred.svg';
import expertise from '../../assets/images/ourexpertise.svg';
import expertiseRed from '../../assets/images/ourexpertisered.svg';
import TechPoCImg from '../../assets/images/tech_poc.svg';
import TechPoCImgError from '../../assets/images/tech_poc_error.svg';
import { Banner } from './banner';
import { Teams } from '../Teams';
import { useFetchOrion, IApiResponse as IOrionApiResponse } from '../../Utils/hooks/useFetchOrion';
import { ValueAdd } from './valueAdd';
import Icon from '../../Components/Icons/icons';
import { TechPoC } from './techPoC';
import { TechEvents } from './techEvents';
import { ITeam } from '../../types/Teams';
import { IValueAdd } from '../../types/ValueAdd';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface TabData {
  label: string;
  iconSrc?: string;
  icon?: string | React.ReactElement;
}

// tech digest tabs data
const TabsData = (
  techDigestsData: TechDigestAttributes | undefined,
  teamsData: ITeam[] | null,
  valueAddData: IValueAdd[] | null
): TabData[] => [
  { label: 'Tech Summary', iconSrc: techDigestsData ? TechSummary : TechSummaryRed },
  { label: 'Our Expertise', iconSrc: techDigestsData?.our_expertise?.data ? expertise : expertiseRed },
  { label: 'PoC', iconSrc: techDigestsData?.tech_pocs?.data?.length ? TechPoCImg : TechPoCImgError },
  {
    label: 'Blogs/TL Articles',
    iconSrc: techDigestsData?.blogs_tl_articles?.data?.length ? Blogs : blogsRed,
  },
  {
    label: 'Recent Updates',
    iconSrc: techDigestsData?.recent_updates?.data?.length ? recentUpdates : recentUpdatesRed,
  },
  {
    label: 'Teams',
    icon: <Icon kind={'teams'} size={18} color={teamsData?.length ? '#0b93df' : '#ff0000'} />,
  },
  {
    label: 'Value Add',
    icon: <Icon kind={'valueAdd'} size={18} color={valueAddData?.length ? '#0b93df' : '#ff0000'} />,
  },
  {
    label: 'Experts',
    icon: (
      <Icon
        kind={'experts'}
        size={24}
        color={techDigestsData?.blogs_tl_articles?.data?.length || valueAddData?.length ? '#0b93df' : '#ff0000'}
      />
    ),
  },
  {
    label: 'Tech Events',
    iconSrc: techDigestsData?.tech_events?.data?.length ? TechSummary : TechSummaryRed,
  },
];

const KbLanguageDetails = styled(Box)(({ theme }) => ({
  padding: '2.5rem',
  display: 'flex',
  flexDirection: 'row',
  height: 'calc(100vh - 6.125rem)',
  overflow: 'hidden',
  gap: '2.5rem',

  '& .listItemText': {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&.active': {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  },

  '& .tab-wrapper': {
    width: '100%',
    backgroundColor: '#fff',
    overflow: 'auto',
  },
  '& .tab-header': {
    padding: '1rem 2.5rem',
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 1,

    '& .MuiTabs-root .MuiTabs-scroller': {
      overflowX: 'auto !important',
    },
  },
}));

const TabIndicatorStyle = {
  height: '0.219rem',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '1rem',
};

// filterByContentType function
function filterByContentType(contentType: string, data: any) {
  return (
    data?.attributes?.contents?.data?.filter(
      (item: any) => item.attributes?.content_type?.data?.attributes?.slug === contentType
    ) || null
  );
}

// getContent function
const getContent = (activeTab: string, data: any, technology: string, briefDetailsDescription: string) => {
  switch (activeTab) {
    case 'Brief Details':
      return <BriefDetails data={briefDetailsDescription} tabName='Brief Details' />;
    case 'Best Practices':
      return <ArticleDetailedContent data={filterByContentType('best-practices', data[0])} tabName='Best Practices' />;
    case 'How To Setup':
      return <ArticleDetailedContent data={filterByContentType('how-to-set-up', data[0])} tabName='How To Setup' />;
    case 'Code Starter Kit':
      return (
        <ArticleDetailedContent data={filterByContentType('code-starter-kit', data[0])} tabName='Code Starter Kit' />
      );
    case 'Our Thoughts':
      return <ArticleDetailedContent data={filterByContentType('our-thoughts', data[0])} tabName='Our Thoughts' />;
    case 'Hard Problems':
      return (
        <ArticleListingContent
          data={filterByContentType('hard-problem', data[0])}
          technology={technology}
          tabName='Hard Problems'
        />
      );
    case 'Innovations':
      return (
        <ArticleListingContent
          data={filterByContentType('innovations', data[0])}
          technology={technology}
          tabName='Innovations'
        />
      );
    case 'Blogs & TL Articles':
      return (
        <ArticleListingContent
          data={filterByContentType('blogs-and-tl-articles', data[0])}
          technology={technology}
          tabName='Blogs & TL Articles'
        />
      );
    case 'Common Mistakes':
      return (
        <ArticleDetailedContent data={filterByContentType('common-mistakes', data[0])} tabName='Common Mistakes' />
      );
    case 'Tech Talks':
      return <TechTalks data={filterByContentType('tech-talks', data[0])} tabName='Tech Talks' />;
    case 'Team':
      return <Team tabName='Team' />;
    default:
      return '';
  }
};

// tabs
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      py={2}
      px={5}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

// a11yProps function
function a11yProps(index: number) {
  return {
    id: `tech-digest-details-tab-${index}`,
    'aria-controls': `tech-digest-details-tabpanel-${index}`,
  };
}

export const TechDigestsDetails = () => {
  const location = useParams();
  const technology = encodeURIComponent(location?.technology!);
  const { data, loading }: IApiResponse<ITechDigest[]> = useFetch(
    `tech-digests?filters[name][$eq]=${technology}&populate=deep,3`
  );
  const { data: teamsData, loading: teamsLoading }: IOrionApiResponse<ITeam[]> = useFetchOrion(
    `technology/getTechDigest/label?techDigestLabel=${technology}`
  );
  const { data: valueAddData, loading: valueAddLoading }: IOrionApiResponse<IValueAdd[]> = useFetchOrion(
    `valueAdd/valueAddsByTag?tag=${technology}`
  );
  const [activeTab] = useState<string>('Brief Details');
  const [techDigestsData, setTechDigestsData] = useState<TechDigestAttributes>();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (data) {
      setTechDigestsData(data[0]?.attributes);
    }
  }, [data]);

  // get tab content
  const content = getContent(activeTab, data, technology, techDigestsData?.description || '');

  return (
    <KbLanguageDetails>
      <Box className='tab-wrapper'>
        <Box className='tab-header'>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor='inherit'
            aria-label='basic tabs example'
            TabIndicatorProps={{
              style: TabIndicatorStyle,
            }}
          >
            {TabsData(techDigestsData, teamsData, valueAddData).map((item: TabData, i: number) => {
              return (
                <Tab
                  key={i}
                  label={item.label}
                  {...a11yProps(i)}
                  sx={{
                    fontSize: '1rem',
                  }}
                  icon={item?.iconSrc ? <img src={item.iconSrc} alt={item.label} /> : item.icon}
                  iconPosition='start'
                />
              );
            })}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {loading ? (
            <CircularLoader />
          ) : (
            <>
              <LeftSideBarHeader
                title={techDigestsData?.name!}
                technologyAdoption={techDigestsData?.maturity_level?.data?.attributes?.name!}
                date={techDigestsData?.updatedAt}
                src={techDigestsData?.icon?.data?.attributes?.url}
                alt={techDigestsData?.icon?.data?.attributes?.alternativeText}
              />
              <Banner date={techDigestsData?.updatedAt} />
              <>{content}</>
            </>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {loading ? (
            <CircularLoader />
          ) : (
            <OurExpertise
              src={techDigestsData?.icon?.data?.attributes?.url}
              alt={techDigestsData?.icon?.data?.attributes?.alternativeText}
              title={techDigestsData?.name!}
              technologyAdoption={techDigestsData?.maturity_level?.data?.attributes?.name!}
              data={techDigestsData?.our_expertise!}
              tabName='Our Expertise'
            />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {loading ? <CircularLoader /> : <TechPoC data={techDigestsData?.tech_pocs} />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          {loading ? (
            <CircularLoader />
          ) : (
            <>
              <BlogsTlArticles data={techDigestsData?.blogs_tl_articles?.data} />
            </>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          {loading ? (
            <CircularLoader />
          ) : (
            <RecentlyUpdatedCard recentUpdateData={techDigestsData?.recent_updates?.data} />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          {teamsLoading ? <CircularLoader /> : <Teams data={teamsData} />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          {valueAddLoading ? <CircularLoader /> : <ValueAdd data={valueAddData} />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={7}>
          {loading ? (
            <CircularLoader />
          ) : (
            <Experts
              valueAddsData={valueAddData}
              teamsData={teamsData}
              blogsData={techDigestsData?.blogs_tl_articles?.data}
            />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={8}>
          {loading ? <CircularLoader /> : <TechEvents data={techDigestsData?.tech_events?.data} />}
        </CustomTabPanel>
      </Box>
    </KbLanguageDetails>
  );
};
