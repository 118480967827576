import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

const KbCircularLoader = styled(Box)<{ marginTop?: string }>(({ theme, marginTop }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: marginTop || '0',
}));

interface CircularLoaderProps {
  marginTop?: string;
}

export const CircularLoader: React.FC<CircularLoaderProps> = ({ marginTop }) => {
  return (
    <KbCircularLoader marginTop={marginTop}>
      <CircularProgress />
    </KbCircularLoader>
  );
};
