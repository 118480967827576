import { Box, Grid, ListItem, Tooltip } from '@mui/material';
import React, { useContext } from 'react';

import {
  KBSubCategory,
  KBSubCategoryCard,
  KBSubCategoryName,
  KBSubCategoryTechDigest,
  KBSubCategoryTechDigestImg,
  KBSubCategoryTechDigestName,
  KBSubCategoryTotalCount,
  SubCategoryGroupCount,
  SubCategoryGroupCountItem,
} from './style';
import { Expertise } from '../../Components/atoms/Card/navigationCard';
import { UserContextType, UserContext } from '../../Context/UserContext';
import { IApiResponse, useFetchOrion } from '../../Utils/hooks/useFetchOrion';
import Blogs from '../../assets/images/blogs.svg';
import Icon from '../../Components/Icons/icons';
import { IOrionBlog, IOrionCategory } from '../../types/types';
import { ITeam } from '../../types/Teams';

interface ArticalListingContentInterface {
  data: any[];
  location?: any;
}

export const ExpertiseContent = ({ data }: ArticalListingContentInterface) => {
  const { category } = useContext<UserContextType>(UserContext);
  const { data: ValueAddData }: IApiResponse<IOrionCategory> = useFetchOrion(
    `tech/category/${category?.name}/value-adds`
  );
  const { data: BlogsData }: IApiResponse<IOrionBlog> = useFetchOrion(`tech/category/${category?.name}/blogs`);
  const { data: TeamsData }: IApiResponse<ITeam> = useFetchOrion(`tech/category/${category?.name}/teams`);

  const getCountsPerExpertiseLevel = (expertiseLevel: string) => {
    const filterData = (data: any, key: string) => {
      return (
        data &&
        data?.technologies
          ?.filter((technology: any) => {
            const techExpertiseLevel = technology?.expertiseLevel;
            const isExpertiseLevelMatch =
              expertiseLevel === 'zero'
                ? techExpertiseLevel === null || techExpertiseLevel === undefined
                : techExpertiseLevel === expertiseLevel;
            return isExpertiseLevelMatch && technology[key]?.length > 0;
          })
          .flatMap((technology: any) => technology[key])
      );
    };

    const valueAddDataPerExpertiseLevel = filterData(ValueAddData, 'valueAdd');
    const blogsDataPerExpertiseLevel = filterData(BlogsData, 'blog');
    const teamsDataPerExpertiseLevel = filterData(TeamsData, 'teams');
    return { valueAddDataPerExpertiseLevel, blogsDataPerExpertiseLevel, teamsDataPerExpertiseLevel };
  };

  return (
    <>
      <KBSubCategory>
        <Grid container spacing={4}>
          {data.map((tech: any, index: number) => {
            const countsPerExpertiseLevel = getCountsPerExpertiseLevel(tech.expertiseLevel);
            return (
              <Grid key={index} item xs={4} display={'flex'} flexDirection={'column'}>
                <KBSubCategoryCard>
                  <KBSubCategoryName>{tech.expertiseName}</KBSubCategoryName>
                  {tech.count === 0 && (
                    <Box sx={{ lineHeight: '1.5', fontWeight: 400 }}>
                      There is currently nothing under this expertise level!
                    </Box>
                  )}
                  <Box display={'flex'} alignItems={'center'} alignSelf={'flex-start'} flexWrap={'wrap'}>
                    {tech.techDigests.map((techDigest: any, index: number) => {
                      return (
                        <KBSubCategoryTechDigest
                          key={index}
                          to={`/${techDigest.attributes.tech_categories.data[0].attributes.slug}/${encodeURIComponent(
                            techDigest.attributes.name
                          )}`}
                        >
                          <Box display={'flex'} marginBottom={1}>
                            {techDigest.attributes.icon.data?.attributes.url && (
                              <KBSubCategoryTechDigestImg
                                width={20}
                                src={techDigest.attributes.icon.data?.attributes.url}
                                alt={techDigest.attributes.icon.data?.attributes.name}
                              />
                            )}
                            <KBSubCategoryTechDigestName>{techDigest.attributes.name}</KBSubCategoryTechDigestName>
                          </Box>

                          <Expertise expertise_level={techDigest.attributes.expertise_level} />
                        </KBSubCategoryTechDigest>
                      );
                    })}
                  </Box>
                  <SubCategoryGroupCount>
                    {ValueAddData && (
                      <ListItem>
                        <Tooltip title={'Value Adds'} arrow placement='top'>
                          <SubCategoryGroupCountItem
                            to={`/${category?.slug}/${tech.expertiseName}/value-adds`}
                            state={{
                              data: countsPerExpertiseLevel.valueAddDataPerExpertiseLevel,
                              item: 'valueAdd',
                            }}
                            style={{ display: 'flex', textDecoration: 'none', alignItems: 'center' }}
                          >
                            <KBSubCategoryTotalCount>
                              {countsPerExpertiseLevel.valueAddDataPerExpertiseLevel.length}
                            </KBSubCategoryTotalCount>
                            <Icon kind={'valueAdd'} size={20} color={'#0b93df'} />
                          </SubCategoryGroupCountItem>
                        </Tooltip>
                      </ListItem>
                    )}
                    {BlogsData && (
                      <ListItem>
                        <Tooltip title={'Blogs'} arrow placement='top'>
                          <SubCategoryGroupCountItem
                            to={`/${category?.slug}/${tech.expertiseName}/blogs`}
                            state={{
                              data: countsPerExpertiseLevel.blogsDataPerExpertiseLevel,
                              item: 'blogs',
                            }}
                            style={{ display: 'flex', textDecoration: 'none', alignItems: 'center' }}
                          >
                            <KBSubCategoryTotalCount>
                              {countsPerExpertiseLevel.blogsDataPerExpertiseLevel.length}
                            </KBSubCategoryTotalCount>
                            <img src={Blogs} alt={'blogs'} width={22} />
                          </SubCategoryGroupCountItem>
                        </Tooltip>
                      </ListItem>
                    )}
                    {TeamsData && (
                      <ListItem>
                        <Tooltip title={'Teams'} arrow placement='top'>
                          <SubCategoryGroupCountItem
                            to={`/${category?.slug}/${tech.expertiseName}/teams`}
                            state={{
                              data: countsPerExpertiseLevel.teamsDataPerExpertiseLevel,
                              item: 'teams',
                            }}
                            style={{ display: 'flex', textDecoration: 'none', alignItems: 'center' }}
                          >
                            <KBSubCategoryTotalCount>
                              {countsPerExpertiseLevel.teamsDataPerExpertiseLevel.length}
                            </KBSubCategoryTotalCount>
                            <Icon kind={'teams'} size={20} color={'#0b93df'} />
                          </SubCategoryGroupCountItem>
                        </Tooltip>
                      </ListItem>
                    )}
                  </SubCategoryGroupCount>
                </KBSubCategoryCard>
              </Grid>
            );
          })}
        </Grid>
      </KBSubCategory>
    </>
  );
};
