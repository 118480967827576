import { Typography } from '@mui/material';
import React from 'react';
import { MarkdownComponent } from '../../Components/atoms/Markdown';
import { RightSideContent, RightSideHeader } from '../../Components/molecules/RightSideBar';
interface ArticleDetailedContentInterface {
  data: any;
  tabName: string;
}
export const ArticleDetailedContent = ({ data, tabName }: ArticleDetailedContentInterface) => {
  const content = data?.[0]?.attributes?.content;
  return (
    <>
      <RightSideHeader>{tabName}</RightSideHeader>
      <RightSideContent>
        {content ? (
          <>
            <MarkdownComponent>{content}</MarkdownComponent>
          </>
        ) : (
          <Typography variant='h3' className='noDataText'>
            Sorry, we couldn't find any results
          </Typography>
        )}
      </RightSideContent>
    </>
  );
};
