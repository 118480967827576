import { Card, CardContent, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

// Styled Components
export const TechEventsContainer = styled(Box)(({ theme }) => ({
  paddingBottom: '3rem',
  '& .swiper-button-next': {
    right: '2px',
    '&:after': {
      fontSize: '2rem',
    },
  },
  '& .swiper-button-prev': {
    left: '2px',
    '&:after': {
      fontSize: '2rem',
    },
  },
}));

export const SkeletonCard = styled(Card)(({ theme }) => ({
  display: 'inline-block',
  width: '100%',
  padding: '1rem',
  marginBottom: '1.5rem',
}));

export const SwiperSlideContent = styled(CardContent)(({ theme }) => ({
  cursor: 'pointer',
}));

export const ModalContent = styled(Box)(({ theme }) => ({
  maxWidth: '850px',
  margin: 'auto',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  '&:focus-visible': {
    outline: 'none',
  },
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  paddingLeft: '.5rem',
}));

export const VideoContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '400px',
  marginBottom: '3rem',
  overflow: 'hidden',
}));

export const IframeStyle = styled('iframe')({
  width: '100%',
  height: '100%',
  border: 0,
  borderRadius: '0.5rem',
});

export const VideoStyle = styled('video')({
  width: '100%',
  height: '100%',
  borderRadius: '0.5rem',
});

export const ImageStyle = styled('img')({
  width: '100%',
  height: '100%',
  border: 0,
  borderRadius: '0.5rem',
});

export const CloseButton = styled(Box)(({ theme }) => ({
  paddingInline: '.5rem',
  cursor: 'pointer',
}));
