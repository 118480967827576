import { Card, CardContent, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

export const UpdateCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: '0 1rem',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
}));

export const CustomCardContent = styled(CardContent)(({ theme }) => ({
  boxSizing: 'border-box',
  height: '131px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '&:last-child': {
    padding: '1.25rem',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  lineClamp: 2,
  lineHeight: 1.2,
  flex: 1,
}));

export const RecentUpdatesContainer = styled(Box)(({ theme }) => ({
  paddingBottom: '3rem',
  '& .swiper-button-next': {
    right: '2px',
    '&:after': {
      fontSize: '2rem',
    },
  },
  '& .swiper-button-prev': {
    left: '2px',
    '&:after': {
      fontSize: '2rem',
    },
  },
}));
