import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import {
  KbOgCardLi,
  KbOgCardLiContent,
  KbOgDetailsCard,
  KbOgDetailsCardTitle,
  KbOgDetailsCardAuthorInfo,
  KbOgCardUl,
} from './style';
import { IOrionBlog } from '../../types/types';

const OgCard: FC<IOrionBlog> = ({ title, author, link, datePublished, type }) => {
  const handleOnClick = () => {
    if (link) window.open(link, '_blank');
  };

  return (
    <KbOgCardLi onClick={handleOnClick}>
      <KbOgCardLiContent>
        <KbOgDetailsCard>
          <KbOgDetailsCardTitle>{title}</KbOgDetailsCardTitle>
          <Box>
            <KbOgDetailsCardAuthorInfo>
              <label>By : </label> <span>{author}</span>
            </KbOgDetailsCardAuthorInfo>
            <KbOgDetailsCardAuthorInfo>
              <label>Published at : </label> <span>{datePublished}</span>
            </KbOgDetailsCardAuthorInfo>
            <KbOgDetailsCardAuthorInfo>
              <label>Type : </label> <span>{type}</span>
            </KbOgDetailsCardAuthorInfo>
          </Box>
        </KbOgDetailsCard>
      </KbOgCardLiContent>
    </KbOgCardLi>
  );
};

export const CategoryBlog: FC<{ data: IOrionBlog[] }> = ({ data }) => {
  return (
    <>
      {data.length ? (
        <KbOgCardUl>
          {data.map((d, index) => (
            <OgCard key={index} {...d} />
          ))}
        </KbOgCardUl>
      ) : (
        <Typography variant='h3' className='noDataText'>
          Currently, there are no blogs available.
        </Typography>
      )}
    </>
  );
};
