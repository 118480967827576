import { useParams } from 'react-router-dom';
import { IApiResponse, useFetchOrion } from '../../Utils/hooks/useFetchOrion';
import { useEffect, useState } from 'react';
import { CategoryValueAdd } from './CategoryValueAdd';
import { IOrionCategory, IOrionTechnology } from '../../types/types';
import { IValueAdd } from '../../types/ValueAdd';

export const CategoryValueAdds = () => {
  const { category } = useParams();
  const [valueAdds, setValueAdds] = useState<IValueAdd[]>([]);
  const { data }: IApiResponse<IOrionCategory> = useFetchOrion(`tech/category/${category}/value-adds`);

  useEffect(() => {
    if (data?.technologies) {
      const result = data.technologies.flatMap((technology: IOrionTechnology) => technology.valueAdd || []);
      setValueAdds(result);
    }
  }, [data]);

  return valueAdds.length ? <CategoryValueAdd valueAdds={valueAdds} /> : null;
};
