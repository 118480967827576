import { List, Typography } from '@mui/material';
import React from 'react';
import { BlogListItem } from '../../Components/molecules/ListItem';
import { RightSideContent, RightSideHeader } from '../../Components/molecules/RightSideBar';
import { IArticle } from '../../types/Article';
interface ArticleListingContentInterface {
  data: any[];
  technology?: string;
  tabName?: string;
}

export const ArticleListingContent = ({ data, technology, tabName }: ArticleListingContentInterface) => {
  return (
    <>
      {data?.length ? (
        <>
          <RightSideHeader>{tabName}</RightSideHeader>
          <RightSideContent>
            <List disablePadding={true}>
              {data.map((item: IArticle) => {
                const {
                  attributes: { Title, createdAt, image, content_type, contributors, additional_info, slug },
                } = item;
                const { url, alternativeText } = image?.data?.length ? image?.data[0]?.attributes : '';
                const contentType = content_type?.data?.attributes?.slug || '';
                const authorName = contributors?.data?.attributes?.username || '';
                const imp = additional_info?.impact || '';
                return (
                  <BlogListItem
                    key={Title}
                    title={Title}
                    articleType={contentType}
                    technology={technology!}
                    author={authorName}
                    date={createdAt}
                    image={url}
                    alt={alternativeText}
                    link={{ pathname: `/${contentType}/${encodeURIComponent(Title)}`, state: { data: slug } }}
                    imgWrapperWidth='11.25rem'
                    impact={imp}
                  />
                );
              })}
            </List>
          </RightSideContent>
        </>
      ) : (
        <Typography variant='h3' className='noDataText'>
          Currently, there are no blog articles available.
        </Typography>
      )}
    </>
  );
};
